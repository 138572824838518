:root {
  --color-primary: #6b9b9b;
  --color-secondary: #005a5b;
  --color-tertiary: #d32f62;
  --color-background: #f0f0f0;
  --color-text: #333333;
}

.The_container {
  min-height: 100vh;
  background-color: var(--color-primary);
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
/*GLOBAL*/

.global_container {
  padding-top: 25vh;
  display: flex;

  flex-direction: column;
  padding-left: 10%;
  padding-right: 10%;
}

.container {
  display: flex;
  flex: 1;
}
.text_W,
.text_small_W,
.text_verysmall_W {
  color: white;
  font-family: "P_Light";
}

.text_W {
  font-size: 1.2vw;
}

.text_small_W {
  font-size: 1vw;
}

.text_verysmall_W {
  font-size: 0.9vw;
}

.text_G {
  color: var(--color-primary);
  font-size: 1.25vw;
}

.text_B {
  color: black;
  font-family: "P_Light";
  text-align: start;
  font-size: 1.1vw;
}

.title {
  color: white;
  font-size: 3vw;
  font-weight: bolder;
  font-family: "R_VariableFont";
}
.title2_big {
  color: var(--color-primary);
  font-size: 3vw;
  font-weight: bold;
  font-family: "R_VariableFont";
}
.title2 {
  color: var(--color-primary);
  font-size: 1.5vw;
  font-weight: bold;
  font-family: "R_VariableFont";
  padding-top: 2%;
}
.title3 {
  color: white;
  font-family: "P_SemiBold";
  font-size: 1.4vw;
}

.row {
  display: flex;
  flex: 1;
}

.double_row {
  display: flex;
  flex: 2;
}

.sub_column {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
  flex: 1;
}

.sub_row {
  display: flex;
  flex-direction: column;
}
.textbox {
  display: flex;
  flex: 1;
  max-width: 30vw;
  flex-direction: column;
  margin-left: auto;
  margin-right: auto;
}

.line {
  background-color: var(--color-secondary);
  border: #ffffff;
  border-style: solid;
  border-radius: 2px;
  border-width: 2px;
  margin-left: 5%;
  margin-right: 5%;
}

/*Main*/
.main_container {
  display: flex;
  flex: 1;
}
.main_logo_text {
  display: flex;
  flex-direction: column;
  flex: 2.5;
}

.main_picture_container {
  flex: 1;
  display: flex;
}
.main_picture {
  max-width: 90%;
}

.main_text_prog_container {
  display: flex;
  flex: 5;
  text-align: start;
  padding-left: 1%;
}
.bottom_main_container {
  display: flex;
}

.button_container {
  display: flex;
  flex: 1;
  align-items: flex-end;
  padding-top: 3%;
  padding-left: 1%;
}

.button {
  display: flex;
  border-style: none;
  background-color: white;
  text-decoration: none;
  color: var(--color-primary);
  font-size: 1.2vw;
  font-weight: bolder;
  border-radius: 10px;
  padding: 1%;
}

/*Festival*/

.festival_container_1 {
  display: flex;
  height: fit-content;
  padding-bottom: 1%;
  min-height: 75vh;
}

.festival_container_2 {
  display: flex;
  padding: 2%;
  height: 100vh;
  margin-left: -12.5%;
  padding-left: 12.5%;
  margin-right: -12.5%;
  padding-right: 12.5%;
  background-color: white;
}

.festival_column {
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-left: 1%;
  padding-right: 1%;
}

.festival_picture {
  background-color: #333333;
  width: 100%;
  aspect-ratio: 1/1;
  border-radius: 50%;
  object-fit: cover;
  flex: 1;
}

.festival_title2 {
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: center;
}
.festival_text_B {
  flex: 2;
}

.festival_container_3 {
  display: flex;
  padding-top: 2%;
  padding-bottom: 2%;
  min-height: 100vh;
  flex-direction: column;
  background-image: url("./images/circle_background.png");
  background-position: center;
  background-size: 200vh;
}
.festival_container_4 {
  display: flex;
  background-color: white;
  margin-left: -12.5%;
  margin-right: -12.5%;
  padding-left: 12.5%;
  padding-right: 12.5%;
}

.festival_column_pictures {
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding-top: 5%;
  padding-bottom: 2.5%;
  align-items: center;
  flex: 1;
}
.festival_column_text {
  padding: 2%;
  padding-bottom: 5%;
  padding-right: 0;
  display: flex;
  flex: 4;
  flex-direction: column;
  justify-content: space-between;
}
.festival_sub_column {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
  flex: 1;
}
.festival_row {
  display: flex;
  flex: 1;
}

.festival_row2 {
  display: flex;
  flex: 2;
}

.festival_row_text {
  text-align: start;
}

.festival_text {
  display: flex;
  flex: 1;
  flex-direction: column;
  text-align: start;
  padding-bottom: 5%;
}

.festival_container_picture {
  display: flex;
  flex: 1;
  align-items: center;
  padding-left: 5%;
}

.festival_pic {
  height: 45%;
  border-radius: 50%;
  object-fit: cover;
  aspect-ratio: 1/1;
}

.festival_pic_wrapped {
  border-radius: 50%;
  aspect-ratio: 1/1;
  object-fit: cover;
  width: 20vw;
  margin-top: -10%;
}

/**********************************
*********** BENEVOLES *************
***********************************/

.benevoles_container {
  display: flex;
  min-height: 75vh;
}

.benevoles_column1 {
  display: flex;
  flex-direction: column;
  flex: 1;
}
.benevoles_column2 {
  display: flex;
  flex-wrap: wrap;
  flex: 1;
  justify-content: center;
  align-items: center;
}

.benevole_title {
  margin-top: 5%;
  margin-bottom: 5%;
  text-align: start;
}

.benevole_text {
  text-align: start;
}
.pic_bene {
  object-fit: cover;
  display: flex;
  aspect-ratio: 1/1;
  height: 30vh;
  padding: 0.5%;
}

/**********************************
********** PARTENAIRES ************
***********************************/

.partenaires_title {
  text-align: left;
  margin-top: 1%;
  margin-bottom: 2%;
}

.partenaires_logos {
  display: flex;
  justify-content: space-around;
}
.pic_part {
  height: 200px;
  margin-bottom: 5%;
}
.partenaires_sponsors {
  display: flex;
  text-align: left;
}

.partenaires_column {
  flex: 1;
  display: flex;
  flex-direction: column;
}

/*
MATCHS D'IMPROS
*/
.match_container {
  display: flex;
  flex: 1;
  flex-direction: column;
  text-align: start;
}
.match_rules_container {
  display: flex;
  flex-direction: row;
}
.match_pres_container {
  margin-bottom: 5%;
}
.match_pres {
  color: white;
  border-radius: 15px;
  text-align: start;
  padding-top: 2%;
  padding-bottom: 5%;
}

.match_button_container {
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  font-weight: bold;
}

.match_button {
  all: unset;
  color: white;
  padding: 1%;
  padding-left: 2%;
  padding-right: 2%;
  border-radius: 50px;
  background-color: var(--color-secondary);
}

.match_pro {
  display: flex;
}

.match_pro_text {
  flex: 2.5;
}

.match_pro_title {
  margin-bottom: 2%;
}

.match_affiche {
  flex: 1;
}

.match_affiche_pic {
  object-fit: contain;
  max-width: 100%;
}

.match_vocab {
  margin-top: 2%;
}

/*Editions*/

.edition_container {
  flex: 1;
  display: flex;
  flex-direction: column;
  text-align: left;
}

.edition_photos {
  display: flex;
  margin-top: 2%;
  flex: 2.5;
  justify-content: center;
  align-items: center;
}
.carousel {
  display: flex;
  justify-content: center;

  max-width: 50vw;
}
.pic_edition {
  max-height: 75vh;
  object-fit: contain;
}

/*
COURS
*/
.cours {
  display: flex;
  flex-direction: column;
}
.cours_container1 {
  flex: 1;
  padding-bottom: 5%;
}
.cours_container2 {
  display: flex;
  flex-direction: column;
  flex: 1;
}
.cours_text_container {
  display: flex;
}
.cours_title {
  text-align: start;
  margin-bottom: 2%;
}

.cours_text1 {
  flex: 1;
  text-align: start;
  padding-right: 2%;
}
.cours_text2 {
  flex: 2;
  padding-left: 2%;
  text-align: start;
  border-color: white;
  border-style: solid;
  border-top: none;
  border-bottom: none;
  border-right: none;
}

.cours_text3 {
  text-align: start;
  padding-bottom: 5%;
  flex: 1;
}

.cours_photos {
  display: flex;
  flex: 2;
}
.cours_main_photo {
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
}
.cours_big {
  max-width: 100%;
  border-radius: 2%;
}
.cours_small_photo {
  flex: 1;
  display: flex;
  flex-direction: column;
}
.cours_small_photo_row {
  flex: 1;
  display: flex;
  justify-content: space-around;
}
.cours_top {
  padding-bottom: 1%;
}
.cours_small {
  border-radius: 5%;
  padding-left: 1%;
  max-width: 50%;
}

/*
PROGRAMMATION 
*/

.progra_cols {
  margin-top: 2.5%;
  display: flex;
}

.progra_col {
  flex: 1;
}
.progra_col_main {
  display: flex;
  justify-content: center;
  text-align: left;
}

.progra_title_day {
  font-size: 2vw;
  font-weight: bold;
}

.item {
  align-items: center;
  margin-top: 10px;
  display: flex;
}

.small_scene {
  width: 30px;
  margin-right: 10px;
}

.heure {
  color: white;
  font-family: "R_VariableFont";
  font-weight: bold;
  font-size: 25px;
  margin-right: 10px;
}

.titre {
  color: white;
  font-family: "P_Regular";
  text-decoration: underline;
  font-size: 20px;
}

.titre:hover {
  color: gainsboro;
}

.legende {
  margin-left: 25%;
  margin-right: 25%;
  display: flex;
  align-items: center;
  justify-content: space-evenly;
}
.legende_item {
  display: flex;
  flex-direction: row;
  align-items: center;
}
/*
Spectacle
*/

.spec_container {
  display: flex;
  margin: 5%;
  margin-top: 2.5%;
  margin-bottom: 0%;
}
.spec_pic_container {
  flex: 1;
  display: flex;
  align-items: center;
}
.spec_pic {
  flex: 1;
  width: 80%;
  aspect-ratio: 1 / 1;
  object-fit: cover;
  border-radius: 100%;
}
.spec_infos {
  margin-left: 5%;
  flex: 2;
  display: flex;
  flex-direction: column;
  color: white;
  text-align: left;
  font-weight: bold;
}
.spec_datas {
  flex: 2;
  display: flex;
  flex-direction: column;
}
.spec_titre {
  flex: 2;
  font-family: "R_VariableFont";
  font-size: 2.5vw;
  display: flex;
  align-items: center;
}

.medium_scene {
  margin-left: 2.5%;
  width: 30px;
}
.spec_cie {
  flex: 1;
  font-size: 1.3vw;
  font-style: italic;
  font-family: "P_BoldItalic";
}
.spec_det {
  flex: 1;
  font-size: 1.3vw;
  font-family: "P_Regular";
}
.spec_resume {
  flex: 4;
  display: flex;
  align-items: center;
  font-family: "P_Regular";
  font-size: 1.1vw;
  font-weight: normal;
}
/*
TARIFS
*/
.tarifs_container {
  display: flex;
  flex-direction: column;
  flex: 1;
}

.tarifs_infos {
  flex: 3;
}

.tarifs_title {
  display: flex;
}
.tarifs_details {
  display: flex;
}

.tarifs {
  flex: 1;
}

.tarifs_bonus {
  margin-top: 5%;
}
.tarifs_day {
  margin-bottom: 10%;
}
.tarifs_subtitle {
  display: flex;
  justify-content: space-between;
  font-family: "R_VariableFont";
  font-weight: bold;
  font-size: 1.6vw;
  color: var(--color-secondary);
}

.tarifs_head {
  display: flex;
  font-family: "R_VariableFont";
  font-weight: bold;
  color: var(--color-secondary);
  justify-content: flex-start;
  font-size: 1.2vw;
  margin-top: 5%;
}

.tarifs_element {
  display: flex;
  justify-content: space-between;
  margin-left: 20px;
  color: white;
  font-family: "P_Regular";
  font-size: 0.8vw;
}

.tarifs_bonus_text {
  border-radius: 20;
  border-radius: 10px;
  margin: 0;
  justify-content: center;
  background-color: var(--color-secondary);
  padding: 1%;
}

.tarifs_band {
  display: flex;
  background-color: var(--color-tertiary);
  flex: 1;
  margin-top: 5%;
  justify-content: center;
  align-items: center;
  margin-left: -12.5%;
  margin-right: -12.5%;
  padding-top: 1%;
  padding-bottom: 1%;
}
.tarif_pic {
  width: 5vw;
}

.banderole_text {
  color: white;
  font-size: 1.5vw;
  font-family: "p_Bold";
  margin-left: 2%;
  margin-right: 2%;
}

/*
VENIR
*/

.venir_container {
  display: flex;
  flex-direction: column;
}
.venir_title {
  flex: 1;
  display: flex;
}

.venir_infos {
  display: flex;
  flex: 2;
}
.venir_carte {
  flex: 1;
  display: flex;
  margin-right: 2.5%;
}
.venir_texts {
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: left;
  margin-left: 2.5%;
}
.venir_subtitle {
  color: var(--color-secondary);
  margin-bottom: 10%;
  font-family: "P_Bold";
}
.venir_text {
  color: white;
  font-family: "P_Regular";
}
.map {
  aspect-ratio: auto;
  margin: 5%;
}
/*Autre*/
.header {
  position: fixed;
  width: 100%;
  background-color: var(--color-secondary);
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-left: 10%;
  padding-right: 10%;
  height: 20vh;
  z-index: 1000;
}
.logo {
  height: 15vh;
}
.logoW {
  width: 100%;
}

.navBar {
  color: white;
  font-family: "R_VariableFont";
  font-size: 1.2vw;
  font-weight: bold;
  justify-content: center;
}

.navBar_text:hover {
  border-radius: 5px;
}

div.dropdown-menu {
  background-color: #9bbabb;
  border: 0;
}

a.dropdown-item {
  color: #ffffff;
}
.row {
  flex: 1;
}

.link {
  text-decoration: none;
  color: inherit;
}
.link:hover {
  text-decoration: none;
  color: inherit;
}

.footer {
  background-color: var(--color-secondary);
  height: 20vh;
  display: flex;
  width: 100%;
  bottom: 0;
  color: white;
  padding-left: 10%;
  padding-right: 10%;
  margin-top: 2%;
}
.footer_logo {
  flex: 1;
  display: flex;
  justify-content: center;

  align-items: center;
}
.footer_img {
  max-height: 15vh;
  object-fit: contain;
}
.footer_links {
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  font-size: 1.5vh;
  padding-top: 2%;
  padding-bottom: 2%;
  flex: 1;
}

.footer_contact {
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;
  padding-top: 2%;
  padding-bottom: 2%;
  flex: 1;
}
.footer_text {
  font-size: 2vh;
  font-family: "R_VariableFont";
  font-weight: bold;
}
.footer_mail {
  object-fit: contain;
  max-width: 1.75vw;
}
.footer_networks {
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;
  padding-top: 2%;
  padding-bottom: 2%;
  flex: 1;
}
.footer_nw_logos {
  display: flex;
  justify-content: space-around;
  width: 30%;
}
.logo_rs {
  flex: 1;
  max-width: 1.75vw;
}

/*##########################*/
/*##### VERSION MOBILE #####*/
/*##########################*/

@media screen and (max-width: 800px) {
  /* autres */
  .global_container {
    padding: 0;
    padding-top: 10vh;
    padding-bottom: calc(10vh + 5%);
    padding-left: 12%;
    padding-right: 12%;
  }
  .header_mobile {
    display: flex;
    background-color: var(--color-secondary);
    height: 10vh;
    position: fixed;
    width: 100%;
    z-index: 1000;
    align-items: center;
    justify-content: center;
  }

  .central_logo {
    flex: 1;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    padding: 2.5%;
  }

  .logo {
    height: 100%;
    width: 40vw;
  }

  .footer_mobile {
    background-color: var(--color-secondary);
    height: 10vh;
    display: flex;
    position: fixed;
    min-height: 10vh;
    bottom: 0;
    width: 100%;
    align-items: center;
    z-index: 1000;
    padding-top: 2%;
  }
  .menu {
    display: flex;
    align-items: baseline;
    justify-content: space-around;
    flex: 1;
  }
  .menu_item {
    display: flex;
    flex: 1;
    align-items: center;
    flex-direction: column;
    color: white;
    font-family: "R_VariableFont";
    text-decoration: none;
  }
  .menu_logo {
    align-self: center;
    max-height: 5vh;
    max-width: 15vw;
  }
  .menu_text {
    padding-top: 5%;
  }

  .menu_link {
    justify-content: center;
    align-items: center;
    display: flex;
    padding-bottom: 2%;
  }

  .line {
    border-style: hidden;
  }

  /* site_styles.css */

  .overlay {
    position: fixed;
    display: flex;
    flex-direction: row;
    flex: 1;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: var(--color-secondary);
    z-index: 1999;
    padding-left: 15%;
    color: white;
  }
  .overlay_main {
    display: flex;
    flex-direction: column;
    margin-right: 7.5%;
  }
  .close_container {
    margin-right: 7.5%;
    margin-top: 10%;
    font-size: 30px;
  }

  .overlay_header {
    flex: 5;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-evenly;
    border-style: solid;
    border-left: none;
    border-right: none;
    border-top: none;
  }

  .text_overlay {
    display: flex;
    text-align: center;
    font-family: "P_Regular";
    font-size: 13px;
    margin-right: 25%;
    margin-left: 25%;
    margin-bottom: 2.5%;
  }
  .nav_mobile {
    flex: 10;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-around;
    width: 100%;
  }
  .nav_menu {
    display: flex;
    flex-direction: column;
    align-items: center;
    font-family: "R_VariableFont";
    font-size: 23px;
    background-color: var(--color-secondary);
  }
  .nav_title {
    text-align: center;
  }
  .nav_sub {
    border-style: solid;
    border-left: none;
    border-right: none;
    border-width: 1px;
    font-family: "R_VariableFont";
    font-size: 14px;
    font-weight: lighter;
    margin-top: 2.5%;
    margin-bottom: 5%;
  }

  .overlay_footer {
    flex: 2;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 10%;
    border-style: solid;
    border-bottom: none;
    border-left: none;
    border-right: none;
  }

  .link_rs {
    display: flex;
    flex: 1;
    justify-content: center;
  }
  .rs_w {
    width: 40%;
  }

  /* Ajoutez d'autres styles selon vos besoins */

  .logoW {
    width: 60%;
  }
  .text_W {
    font-size: 15px;
  }
  /*Main*/
  .main_container {
    display: flex;
    flex-direction: column;
    flex: 1;
  }

  .main_picture_container {
    display: flex;
    flex: 1;
    margin-top: 5%;
    padding-bottom: 7.5%;
    justify-content: center;
  }
  .main_picture {
    max-width: 75vw;
  }
  .main_text_prog_container {
    display: flex;
    flex: 2;
    flex-direction: column;
    text-align: start;
    justify-content: space-between;
  }
  .main_logo_text {
    display: flex;
    flex-direction: column;
  }
  .main_text_container {
    display: flex;
    flex: 1;
    font-size: 16px;
    text-align: start;
    font-family: "P_Medium";
  }

  .button_container {
    margin-top: 5%;
    display: flex;
    justify-content: flex-end;
  }

  .button {
    display: flex;
    border-style: none;
    background-color: white;
    text-decoration: none;
    color: var(--color-primary);
    font-size: 17px;
    font-weight: bolder;
    border-radius: 5px;
  }

  .mobile_page_title {
    text-align: left;
    color: white;
    font-weight: bolder;
    font-family: "P_Bold";
    font-size: 35px;
    margin-top: 5%;
    margin-bottom: 5%;
  }

  /************/
  /* FESTIVAL */
  /************/
  .festival_container {
    height: auto;
    display: flex;
    flex-direction: column;
  }
  .festival_container_1 {
    flex-direction: column;
    text-align: left;
  }
  .festival_container_picture {
    display: flex;
    flex: unset;
    align-items: center;
    padding-left: 0;
    padding-bottom: 7.5%;
  }

  .festival_picture {
    border-radius: 15px;
    aspect-ratio: 16/9;
  }

  .festival_container_2 {
    flex: 1;
    display: flex;
    padding: 0;
    margin-left: 0;
    padding-left: 0;
    margin-right: 0;
    padding-right: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    background-color: var(--color-primary);
  }
  .fest_contain {
    flex: 0.5;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .fest_pic {
    margin-top: 5%;
    margin-bottom: 5%;
    border-radius: 50%;
    width: 100%;
    height: 100%;
    object-fit: cover;
    aspect-ratio: 1/1;
  }

  .festival_title2_container {
    flex: 1;
  }
  .title2 {
    margin-top: 5%;
    margin-bottom: 5%;
    font-size: 25px;
    color: white;
  }
  .festival_container_text {
    flex: 6;
    text-align: left;
    align-items: flex-start;
    padding-bottom: 5%;
  }
  .text_B {
    color: white;
    font-weight: bold;
    font-size: 15px;
  }

  .engagement_container {
    margin-left: 5%;
    margin-right: 5%;
  }
  .engagement_title2_container {
    text-align: left;
    margin-bottom: 5%;
  }

  .engagement_container_textB {
    margin-bottom: 5%;
  }
  .titleE {
    font-size: 25px;
    color: white;
    font-weight: bold;
  }
  .text_E {
    text-align: left;
    font-weight: normal;
  }

  /*************/
  /* BENEVOLES */
  /*************/

  .benevoles_container {
    display: flex;
    flex-direction: column;
    margin-left: 5%;
    margin-right: 5%;
  }

  .benevoles_pictures {
    display: flex;
    flex-wrap: wrap;
  }
  .pic_bene {
    flex: 1;
    width: 25vw;
    margin: 2.5px;
    aspect-ratio: 1/1;
    height: auto;
  }

  /**********/
  /* MATCHS */
  /**********/

  .match_container {
    margin-left: 5%;
    margin-right: 5%;
    display: flex;
  }
  .match_main_text {
    color: white;
    text-align: left;
    margin-bottom: 5%;
  }
  .mobile_subtitle {
    color: white;
    text-align: left;
    font-size: 25px;
    font-family: "P_Bold";
    margin-bottom: 2.5%;
  }

  .edition_photos {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh; /* Ajustez la hauteur selon vos besoins */
    z-index: inherit;
  }
  /*
  .carousel_container {
    flex: 1;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    background-color: #c9b43e;
  }

  .carousel {
    flex: 1;
    width: 10px; /* Ajustez la largeur du carousel selon vos besoins 
    max-height: 200px; /* Ajustez la hauteur maximale selon vos besoins 
    background-color: #9bbabb;
  }
  */
  .carousel {
    display: flex;
    justify-content: center;
    min-width: 40vw;
    max-width: 85vw;
  }

  .vocab {
    display: flex;
    flex-direction: column;
  }
  .vocab_row {
    display: flex;
  }
  .vocab_col {
    flex: 1;
    margin-left: 10px;
    margin-right: 10px;
  }
  .vocab_title {
    color: white;
    font-weight: bold;
    font-size: 20px;
    margin-bottom: 5px;
  }
  .vocab_text {
    color: white;
    font-size: 15px;
    margin-bottom: 10%;
  }

  /*
COURS
*/
  .cours {
    display: flex;
    flex-direction: column;
    margin-left: 5%;
    margin-right: 5%;
    padding-left: unset;
    padding-right: unset;
  }
  .cours_container1 {
    flex: 1;
    min-height: 80vh;
    display: flex;
    flex-direction: column;
  }
  .cours_container2 {
    display: flex;
    flex-direction: column;
    flex: 1;
    padding-top: 2%;
    min-height: unset;
  }
  .cours_text_container {
    display: flex;
    flex-direction: column;
  }
  .cours_title {
    text-align: start;
    margin-bottom: 2%;
  }

  .cours_text1 {
    flex: 1;
    font-size: 12px;
    text-align: start;
    padding-right: unset;
  }
  .cours_text2 {
    flex: 2;
    text-align: start;
    border-color: unset;
    border-style: unset;
    border-top: unset;
    border-bottom: unset;
    border-right: unset;
    padding-left: unset;
    margin-bottom: 5%;
  }

  .cours_text3 {
    text-align: start;
    margin-bottom: 5%;
    flex: 1;
  }

  .cours_photos {
    display: flex;
    flex: 2;
  }
  .cours_main_photo {
    flex: 1;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .cours_photo {
    margin-bottom: 5%;
    margin-top: 5%;
    display: flex;
    justify-content: center;
  }
  .cours_big {
    max-height: 100%;
    max-width: 100%;
    border-radius: 20px;
  }
  .cours_small_photo {
    flex: 1;
    display: flex;

    flex-direction: column;
  }
  .cours_small_photo_row {
    flex: 1;
    display: flex;
    justify-content: center;
  }

  .cours_small {
    width: 32.5vw;
    max-width: unset;
    height: 32.5vw;
    object-fit: cover;
    border-radius: 20px;
    margin-bottom: 2.5%;
    margin-left: 0%;
    margin-right: 2.5%;
  }
  .no_center_r {
    object-position: -75px 0; /* Modifier les valeurs selon le décalage souhaité */
  }
  .no_center_l {
    object-position: 0px 0; /* Modifier les valeurs selon le décalage souhaité */
  }
  /**********/
  /* TARIFS */
  /**********/

  .tarifs_container {
    display: flex;
    flex-direction: column;

    flex: 1;
  }

  .tarifs_infos {
    flex: 3;
  }

  .tarifs_title {
    display: flex;
  }
  .tarifs_details {
    display: flex;
    flex: 8;
    flex-direction: column;
    margin-bottom: unset;
  }

  .tarifs {
    flex: 1;
  }
  .tarifs_text {
    font-size: 13px;
  }

  .tarifs_subtitle {
    display: flex;
    justify-content: space-between;
    font-family: "R_VariableFont";
    font-weight: bold;
    margin-bottom: 2%;
    font-size: 19px;
    margin-right: unset;
    color: var(--color-secondary);
  }

  .tarifs_head {
    flex: 2;
    display: flex;
    font-family: "R_VariableFont";
    font-weight: bold;
    color: #d6d5bd;
    justify-content: flex-start;
    font-size: 16px;
    margin-top: 5%;
  }

  .tarifs_bonus_text {
    font-size: 1.2vh;
  }

  .tarifs_band {
    display: flex;
    background-color: var(--color-tertiary);
    flex: 1;
    margin-top: 5%;
    justify-content: center;
    align-items: center;
    margin-left: -15.65%;
    margin-right: -15.65%;
    padding: 2%;
  }
  .tarif_pic {
    width: 15vw;
  }
  .banderole_text {
    color: white;
    font-size: 16px;
    font-family: "p_Bold";
    margin-left: 2%;
    margin-right: 2%;
  }

  /*******/
  /*VENIR*/
  /*******/

  .venir_infos {
    display: flex;
    flex-direction: column;
  }
  .venir_carte {
    flex: 1;
    display: flex;
    margin-right: unset;
  }
  .venir_texts {
    flex: 1;
    display: flex;
    flex-direction: column;
    margin-top: 5%;
    text-align: left;
    margin-left: unset;
  }
  .venir_subtitle {
    font-size: 14px;
  }
  .venir_text {
    font-size: 14px;
  }

  /*
Spectacle
*/

  .spec_container {
    display: flex;
    flex-direction: column;
    margin-top: 5%;
  }
  .spec_pic_container {
    flex: unset;
    display: flex;
    justify-content: center;
  }
  .spec_pic {
    flex: unset;
  }
  .spec_infos {
    margin-left: unset;
    display: flex;
    flex-direction: column;
    color: white;
    text-align: left;
    font-weight: bold;
  }
  .spec_datas {
    flex: 2;
    display: flex;
    flex-direction: column;
  }
  .spec_titre {
    flex: 2;
    font-family: "R_VariableFont";
    font-size: 25px;
    display: flex;
    align-items: center;
  }

  .medium_scene {
    margin-left: 10px;
    width: 30px;
  }
  .spec_cie {
    flex: 1;
    font-size: 14px;
    font-style: italic;
    font-family: "P_BoldItalic";
  }
  .spec_det {
    flex: 1;
    font-size: 14px;
    font-family: "P_Regular";
  }
  .spec_resume {
    flex: 4;
    display: flex;
    align-items: flex-start;
    font-family: "P_Regular";
    font-size: 14px;
    line-height: unset;
    font-weight: normal;
    padding-top: 5%;
  }

  /*PROGRA*/

  .progra_container {
    display: flex;
    flex-direction: column;
    margin-left: 5%;
    margin-right: 5%;
  }
  .progra_header {
    margin-left: unset;
  }
  .progra_cols {
    display: flex;
  }

  .progra_col {
    display: flex;
    flex: 1;
    flex-direction: column;
    align-items: left;
    margin: unset;
    padding-bottom: 15%;
  }

  .progra_col_main {
    flex: 9;
  }

  .progra_leg {
    width: fit-content;
  }

  .legende {
    margin: unset;
    display: flex;
    justify-content: space-around;
    align-items: baseline;
  }

  .legende_item {
    flex: 1;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
  .small_scene {
    width: 30px;
    margin-right: 10px;
  }
  .legende_icon {
    margin: unset;
  }
  .legende_text {
    flex: 1;
    font-weight: normal;
  }

  .progra_title_day {
    font-size: 25px;
    margin-top: 5px;
    margin-bottom: 5px;
    color: #ffffff;
  }

  .progra_list {
    display: flex;
    flex-direction: column;
    text-align: left;
  }

  .item {
    display: flex;
    align-items: first baseline;
  }
  .titre {
    font-size: 14px;
  }

  .heure {
    color: white;
    font-family: "R_VariableFont";
    font-weight: bold;
    font-size: 15px;
    margin-right: 10px;
  }
}
